<template>
<div class="d-flex flex-stack" style="padding-bottom:10px;">
    <div class="page-title d-flex align-items-center me-3 flex-wrap mb-5 mb-lg-0 lh-1">
    </div>
    <div class="col-md-6" align="right">
    <div class="me-4">
        <button
            href="#"
            data-bs-toggle="modal"
            data-bs-target="#modal_print_report"
            id="report_print_button"
            class="btn btn-sm btn-primary"
        >
            Print Report
        </button>
    </div>
    </div>
</div>
<div class="card">
    <div class="card-body">
        <div class="card-p position-relative">
            <div class="row m-0">
                <router-link v-bind:to="'/management/admin-report/editor/'+enrollmentVerificationActiveId" class="col bg-light-danger px-6 py-8 rounded-2 me-7" style="text-align: center;">
                    <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                    <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                    </span>
                    <span class="text-danger fw-bold fs-6 mt-2"> Enrollment Verification(Active) </span>
                </router-link>
                <router-link v-bind:to="'/management/admin-report/editor/'+enrollmentVerificationInactiveId" class="col bg-light-danger px-6 py-8 rounded-2 me-7" style="text-align: center;">
                    <span class="svg-icon svg-icon-3x svg-icon-danger d-block my-2">
                    <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                    </span>
                    <span class="text-danger fw-bold fs-6 mt-2"> Enrollment Verification(Inactive) </span>
                </router-link>
                <router-link v-bind:to="'/management/admin-report/editor/'+acceptanceLetterId" class="col bg-light-success px-6 py-8 rounded-2" style="text-align: center;">
                    <span class="svg-icon svg-icon-3x svg-icon-success d-block my-2">
                    <inline-svg src="/media/icons/duotune/files/fil008.svg" />
                    </span>
                    <span class="text-success fw-bold fs-6 mt-2"> Acceptance Letter </span>
                </router-link>
            </div>
            <div class="row m-0" style="margin-top:20px !important;">
                <router-link v-bind:to="'/management/admin-report/editor/'+enrollmentVerificationActiveNoHeaderId" class="col bg-light-warning px-6 py-8 rounded-2 me-7" style="text-align: center;">
                    <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                    </span>
                    <span class="text-warning fw-bold fs-6 mt-2"> Enrollment Verification(Active) - no header </span>
                </router-link>
                <router-link v-bind:to="'/management/admin-report/editor/'+enrollmentVerificationInactiveNoHeaderId" class="col bg-light-warning px-6 py-8 rounded-2 me-7" style="text-align: center;">
                    <span class="svg-icon svg-icon-3x svg-icon-warning d-block my-2">
                    <inline-svg src="/media/icons/duotune/abstract/abs027.svg" />
                    </span>
                    <span class="text-warning fw-bold fs-6 mt-2"> Enrollment Verification(Inactive) - no header </span>
                </router-link>
                <div class="col px-6 py-8 rounded-2"></div>
            </div>
      </div>
    </div>
</div>
<PrintReport />
</template>

<script lang="ts">
import { defineComponent, onMounted, computed, ref, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import PrintReport from   "@/views/management/modals/PrintReport.vue"

export default defineComponent({
  name: "admin-report",
  components: {
      PrintReport
  },
  setup() {
      const enrollmentVerificationInactiveId = 2;
      const enrollmentVerificationActiveId = 3;
      const acceptanceLetterId = 4;
      const enrollmentVerificationActiveNoHeaderId = 9;
      const enrollmentVerificationInactiveNoHeaderId = 8;

      onMounted(() => {
        setCurrentPageBreadcrumbs("Admin Report", ["Management"]);
      });

      return {
          enrollmentVerificationInactiveId,
          enrollmentVerificationActiveId,
          acceptanceLetterId,
          enrollmentVerificationActiveNoHeaderId,
          enrollmentVerificationInactiveNoHeaderId
      };
  },
});
</script>
