<template>
  <div
    class="modal fade"
    id="modal_print_report"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered mw-700px">
        <div class="modal-content">
            <div class="modal-header">
                <h2>
                    Print Report
                </h2>
                <div
                    class="btn btn-sm btn-icon btn-active-color-primary"
                    data-bs-dismiss="modal"
                    @click="resetForm()"
                >
                    <span class="svg-icon svg-icon-1">
                    <inline-svg src="/media/icons/duotune/arrows/arr061.svg" />
                    </span>
                </div>
            </div>

            <div class="modal-body py-lg-10 px-lg-10">
                <el-form
                    id="print_report_form"
                    :model="reportData"
                    :rules="rules"
                    ref="formRef"
                    class="form"
                >
                    <div class="row mb-6">
                        <label
                            class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
                        >
                            <span class="required">Report Type</span>
                        </label>
                        <div class="col-lg-9">
                            <el-form-item prop="type">
                                <el-select
                                    class="form-select-solid"
                                    v-model="reportData.reportId"
                                    @change="getTemplate">
                                    <el-option v-for="template in templates" :key="template.value" :label="template.label" :value="template.value">
                                        {{template.label}}
                                    </el-option>
                                </el-select>
                            </el-form-item>
                        </div>
                    </div>
                    <template v-if="variables">
                        <div class="row mb-6" v-for="variable in variables" :key="variable">
                            <label
                                class="d-flex align-items-center fs-5 fw-bold mb-2 col-lg-3"
                            >
                                <span class="required">{{variable.label}}</span>
                            </label>
                            <div class="col-lg-9">
                                <el-form-item prop="title">
                                    <input
                                        v-model="variable.value"
                                        style="width:100%;"
                                        class="form-control form-control-lg form-control-solid"
                                    />
                                </el-form-item>
                            </div>
                        </div>
                    </template>
                    <div class="text-center">
                    <button
                        id="close_print_button"
                        class="btn btn-white me-3"
                        data-bs-dismiss="modal"
                        @click="resetForm()"
                    >
                        Cancel
                    </button>

                    <el-button type="primary" @click="print()" v-if="reportData.reportId">
                        Print
                    </el-button>
                    </div>
                </el-form>
            </div>
        </div>
    </div>
  </div>
</template>

<script lang="ts">
import { defineComponent, ref } from "vue";
import Swal from "sweetalert2/dist/sweetalert2.js";
import ApiService from "@/core/services/ApiService";
import moment from "moment";
import Mustache from "mustache";

export default defineComponent({
  name: "create-record-modal",
  components: {
  },
  setup() {
    const formRef = ref<null | HTMLFormElement>(null);
    const reportData = ref<any>({});
    const templates = [
        {label:'Acceptance Letter', value:4},
        {label:'Enrollment Verification(Active)', value:3},
        {label:'Enrollment Verification(Inactive)', value:2},
        {label:'Enrollment Verification(Active) - No Header', value:9},
        {label:'Enrollment Verification(Inactive) - No Header', value:8}
    ];
    const template = ref('');
    const variables = ref([] as any);
    const signatureId = 43;

    const getTemplate = () => {
        ApiService.setHeader();
        ApiService.get("report/"+reportData.value.reportId)
            .then(({data}) => {
                template.value = data.data.report_content;
                variables.value = Mustache.parse(template.value)
                                    .filter(function(v) { return v[0] === 'name' })
                                    .filter(function(v) { return v[1] !== 'signature_url'})
                                    .map(function(v) { return { label : v[1], value: '' };});
                variables.value.filter(function(v) { return v.label == 'print_date'}).map(function(v) {v.value=moment().format('MM/DD/YYYY')})
            })
    }

    const getSignatureUrl = () => {
        return new Promise((resolve, reject) => {
            ApiService.setHeader();
            ApiService.get("file/url/"+signatureId)
                .then(({ data }) => {
                    resolve(data);
                })
                .catch(({ response }) => {
                    console.log('getSignatureUrl error => '+response.data.errors);
                    reject(new Error ('getSignatureUrl => '+response.data.errors));
                });;
        });
    }

    const rules = ref({  
        type: [
            {
                required: true,
                message: "Please select event type",
                trigger: "blur",
            },
        ],
        title: [
            {
                required: true,
                message: "Please enter the event title",
                trigger: "change",
            },
        ],
    });
    
    const print = () => {
        if (!formRef.value) {
            return;
        }

        getSignatureUrl().then((data : any)=>{
            let variableClass= {} as any;
            variables.value.map(x=>{variableClass[x.label] = x.value;});
            variableClass.signature_url = data.url;

            let printedAcceptanceLetter = Mustache.render(template.value, variableClass);
            let reportWindow = window.open("", "Title", "toolbar=no,location=no,directories=no,status=no,menubar=no,scrollbars=yes,resizable=yes,width=900,height=700,top="+(screen.height-400)+",left="+(screen.width-840));
            if(reportWindow){
                reportWindow.document.body.innerHTML = printedAcceptanceLetter;
            };
        })
    };

    const resetForm = () =>{
      variables.value = [];
    }

    return {
      formRef,
      moment,
      print,
      reportData,
      rules,
      templates,
      getTemplate,
      variables,
      resetForm
    };
  },
});
</script>

<style scoped>
* >>> .el-form-item {
  margin-bottom: 0px;
}
</style>