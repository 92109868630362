
import { defineComponent, onMounted, computed, ref, reactive } from "vue";
import { setCurrentPageBreadcrumbs } from "@/core/helpers/breadcrumb";
import PrintReport from   "@/views/management/modals/PrintReport.vue"

export default defineComponent({
  name: "admin-report",
  components: {
      PrintReport
  },
  setup() {
      const enrollmentVerificationInactiveId = 2;
      const enrollmentVerificationActiveId = 3;
      const acceptanceLetterId = 4;
      const enrollmentVerificationActiveNoHeaderId = 9;
      const enrollmentVerificationInactiveNoHeaderId = 8;

      onMounted(() => {
        setCurrentPageBreadcrumbs("Admin Report", ["Management"]);
      });

      return {
          enrollmentVerificationInactiveId,
          enrollmentVerificationActiveId,
          acceptanceLetterId,
          enrollmentVerificationActiveNoHeaderId,
          enrollmentVerificationInactiveNoHeaderId
      };
  },
});
